import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';
import {
  createMembersAreaWidgetPluginService,
  createWidgetPluginExports,
} from '@wix/members-area-widget-plugin-lib/viewer';

import { ISettingsEvents } from './Widget/settingsEvents';
import { providersPropsMap } from '../../contexts/main/providersPropsMap';
import { ScreenNames } from '../../contexts/main/biInterfaces';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const widgetPluginService = createMembersAreaWidgetPluginService();
  const { setProps } = flowAPI.controllerConfig;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};

  const initialise = async () => {
    const initialProps = await providersPropsMap({
      biSettings: {
        screenName: ScreenNames.MAChallengeList,
        preventAutomaticallySendChallengeId: true,
      },
      flowAPI,
      enabledProviders: [
        'challengesListDataProvider',
        'paidPlansProvider',
        'participantProvider',
      ],
    });

    flowAPI.bi.updateDefaults({
      origin: flowAPI.controllerConfig.wixCodeApi.window.viewMode,
      _uuid: flowAPI.controllerConfig.platformAPIs.bi.ownerId,
      screenName: ScreenNames.ChallengeListWidget,
    });

    try {
      flowAPI.controllerConfig.setProps({
        ...initialProps,
      });
    } catch (e) {
      console.error('[challenge list]: couldnt set initial props', e);
    }

    flowAPI.controllerConfig.setProps({
      editorLoaded: true,
      isRendered: true,
    });
  };

  // settings panel pub/sub https://github.com/wix-private/tpa-settings#events-between-settings-panel-and-applications
  const settingsEventsHandler = createEventHandler<ISettingsEvents>(publicData);
  // subscribe to event. Supports Typescript and events keys maybe only the keys of IEvents interface.

  settingsEventsHandler.on('showMAEmptyList' as any, (value) => {
    setProps({
      showMAEmptyList: value,
    });
  });

  return {
    async pageReady() {
      const isRendered = widgetPluginService.getIsRendered();

      if (!isRendered) {
        setProps({ isRendered });
        return Promise.resolve();
      }

      await initialise();
    },
    updateConfig($w, config) {
      // notify (events should be fired)
      settingsEventsHandler.notify(config.publicData.COMPONENT || {});
    },
    exports: () => {
      return createWidgetPluginExports(widgetPluginService, initialise);
    },
  };
};

export default createController;
